#presentation-content-wrapper {
  min-width: 0;
  width: 100%;
}

#presentation-container {
  height: calc(100vh - 75px);
  overflow-y: scroll;
  padding-top: $spacer * 5;
  padding-bottom: $spacer * 5;
}

.presentation-header {
  position: sticky;
  top: 0;
  left: 0;
}

.presentation-item-container {
  padding-top: $spacer * 3.75;
  padding-bottom: $spacer * 3.75;
  border-bottom: 1px solid $secondary;
}

.presentation-page {
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $medium !important;
    font-family: $font-family-base !important;
  }

  p {
    line-height: 30px !important;
    font-weight: $font-weight-base !important;
  }

  .component-wrapper {
    .component-title {
      margin-bottom: $spacer * 4;
    }
  }

  // &.presentation-preview-page {
  //   .frontend-client-information {
  //     padding-bottom: $spacer * 2;
  //   }

  //   .presentation-item-container {
  //     padding-top: $spacer * 2.5;
  //     padding-bottom: $spacer * 2.5;
  //   }
  // }
}

.CopyShareableLinkOverlay {
  display: none;
}
